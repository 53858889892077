import { IconProps, useClipboard, useToast } from '@chakra-ui/react'
import { CheckIcon, CopyIcon } from '@chakra-ui/icons'
import DoppelLink from './doppel_link'

type Props = {
  link: string
} & IconProps

const LinkCopy: React.FC<Props> = ({ link, ...rest }: Props) => {
  const { hasCopied, onCopy } = useClipboard(link, { timeout: 2000 })
  const toast = useToast()
  const sharedProps = {
    ml: 2,
    ...rest,
  }
  if (hasCopied) {
    return <CheckIcon {...sharedProps} />
  }
  return (
    <CopyIcon
      cursor={'pointer'}
      onClick={() => {
        onCopy()
        toast({
          title: 'Link copied',
          description: (
            <DoppelLink lineHeight="1.5" maxW="300px" name={link} noOfLines={2} />
          ),
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      }}
      {...sharedProps}
    />
  )
}

export default LinkCopy
