import { IconButton } from '@chakra-ui/react'
import { DOPPEL_BUTTON_GREY, DOPPEL_WHITE } from '../../utils/style'
import { FaFileUpload } from 'react-icons/fa'

type Props = {
  isScreenshotUploadFormOpen: boolean
  setIsScreenshotUploadFormOpen: any
}

const SpoofRequestManualScreenshotButton: React.FC<Props> = ({
  isScreenshotUploadFormOpen,
  setIsScreenshotUploadFormOpen,
}) => {
  return (
    !isScreenshotUploadFormOpen && (
      <IconButton
        aria-label=""
        bgColor={DOPPEL_BUTTON_GREY}
        icon={<FaFileUpload />}
        onClick={() => {
          setIsScreenshotUploadFormOpen(!isScreenshotUploadFormOpen)
        }}
        size="xs"
        textColor={DOPPEL_WHITE}
      />
    )
  )
}

export default SpoofRequestManualScreenshotButton
