import React, { useEffect, useState } from 'react'
import {
  Accordion,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Spinner,
  Tooltip,
  VStack,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  Text,
  useToast,
  Icon,
  useDisclosure,
} from '@chakra-ui/react'
import {
  FaChild,
  FaCopy,
  FaCopyright,
  FaFileContract,
  FaFish,
  FaShoppingBasket,
  FaTrademark,
  FaUserFriends,
} from 'react-icons/fa'
import { IoMdAdd } from 'react-icons/io'
import {
  useDeleteEnforcementRequestMutation,
  useUpdateEnforcementRequestStatusMutation,
  useGetJiraMetadataFromEnforcementRequestIdLazyQuery,
  useGetStateTransitionInfoFromCollectionReportChangesLazyQuery,
} from '@/generated/graphql'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import { delayRefetchedQueries } from '@/utils'
import { ENFORCEMENT_STATUS, JIRA_TICKET_STATUS, ProductType } from '@/utils/constants'
import DetailRow from '@/components/report_detail/detail_row'
import DoppelLink from '@/components/report_detail/doppel_link'
import DeleteButton from '@/components/shared/delete_button'
import EditDropdown from '@/components/shared/edit_dropdown'
import ProgressBar, { ProgressState } from '@/components/shared/progress_bar'
import {
  DOPPEL_BREACH_RED_SHADE,
  DOPPEL_ENCRYPT_GREEN_SHADE,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
} from '@/utils/style'
import DoppelDefaultButton from '@/components/shared/doppel_default_button'
import { getExternalUser } from '@/utils/reports/report_utils'
import { WarningIcon } from '@chakra-ui/icons'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'
import RetractionScreenshotViewButton from '@/components/enforcements/retractions/retraction_screenshot_view_button'
import RetractionButton from '@/components/enforcements/retractions/retraction_button'

export const POSITIVE_ENFORCEMENT_STATUSES = [
  ENFORCEMENT_STATUS.STAGED,
  ENFORCEMENT_STATUS.REPORTED,
  ENFORCEMENT_STATUS.APPROVED,
  ENFORCEMENT_STATUS.REROUTED,
]
export const NEGATIVE_ENFORCEMENT_STATUSES = [
  ENFORCEMENT_STATUS.FAILED,
  ENFORCEMENT_STATUS.BLOCKED,
  ENFORCEMENT_STATUS.CANCELED,
  ENFORCEMENT_STATUS.REJECTED,
  ENFORCEMENT_STATUS.RETRACTED,
  ENFORCEMENT_STATUS.RETRACTION_SENT,
]

export const RETRACTION_ENFORCEMENT_STATUSES = [
  ENFORCEMENT_STATUS.RETRACTION_SENT,
  ENFORCEMENT_STATUS.RETRACTED,
]

export const PROGRESS_BAR_LENGTH = 3

export const ENFORCEMENT_TYPE_TO_ICON = {
  phishing: <FaFish />,
  trademark: <FaTrademark />,
  copyright: <FaCopyright />,
  impersonation: <FaUserFriends />,
  counterfeit: <FaCopy />,
  product_misuse: <FaShoppingBasket />,
  underage_use: <FaChild />,
  dmca: <FaFileContract />,
}

// temp variable while not all products have enforcement details
const DETAILS_ENABLED_PRODUCTS = [ProductType.SOCIAL_MEDIA, ProductType.DOMAIN]

// Used for retraction progress bar since 'retraction sent' is too long
const RETRACTION_SENT_PROGRESS_BAR_TEXT = 'retracting'

type HistoricRetractionInfo = {
  old_status: string // Should be accepted or reported
  retraction_sent_time: Date
}

export default function EnforcementStatuses({
  product,
  enforcementRequests,
  isEnforcementRequestLoading,
  screenshots = [],
  onEnforcementUpload,
  client,
  setShouldShowEnforcementDetails,
  setRetractionFormState,
}) {
  const [isEmployeeView] = useIsEmployeeView()
  const toast = useToast()
  const cancelRef = React.useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [enforcementRequestIdToDelete, setEnforcementRequestIdToDelete] = useState(null)

  const [updateEnforcementRequestStatus, { loading: updateEnforcementStatusLoading }] =
    useUpdateEnforcementRequestStatusMutation()

  const [deleteEnforcementRequest, { loading: deleteEnforcementStatusLoading }] =
    useDeleteEnforcementRequestMutation()

  const [getJiraMetadata] = useGetJiraMetadataFromEnforcementRequestIdLazyQuery()
  const [getStateTransitionInfo] =
    useGetStateTransitionInfoFromCollectionReportChangesLazyQuery()

  // Map for each enforcement request id to retraction info
  // From enforcement request id -> previous state before retraction
  const [
    enforcementRequestsStateBeforeRetraction,
    setEnforcementRequestsStateBeforeRetraction,
  ] = useState(new Map<string, HistoricRetractionInfo>())

  // Map from enforcement request id to whether the ticket is active (true is active)
  const [enforcementRequestActiveStatus, setEnforcementRequestActiveStatus] = useState(
    new Map<string, boolean>(),
  )

  // Fetch the state before retraction was sent from collection report changes table
  useEffect(() => {
    const fetchData = async () => {
      if (!enforcementRequests) return
      for (const enforcementRequest of enforcementRequests) {
        if (!RETRACTION_ENFORCEMENT_STATUSES.includes(enforcementRequest.status)) {
          continue
        }
        // Get old status and retraction sent date
        const response = await getStateTransitionInfo({
          variables: {
            enforcement_request_id: enforcementRequest.id,
            new_value: ENFORCEMENT_STATUS.RETRACTION_SENT.toString(),
            type: 'enforcement_request_status',
          },
        })
        if (response.data) {
          const retraction_transition_data = response.data.collection_report_changes
          if (!retraction_transition_data || retraction_transition_data.length === 0)
            continue

          const mostRecentData = retraction_transition_data.reduce(
            (latest, current) => {
              return new Date(current.timestamp) > new Date(latest.timestamp)
                ? current
                : latest
            },
          )
          // Set timestamp, old status if they are real values in the table
          if (mostRecentData?.old_value && mostRecentData?.timestamp) {
            const newRetractionInfo: HistoricRetractionInfo = {
              old_status: mostRecentData.old_value,
              retraction_sent_time: new Date(mostRecentData.timestamp),
            }
            setEnforcementRequestsStateBeforeRetraction(
              new Map(
                enforcementRequestsStateBeforeRetraction.set(
                  enforcementRequest.id,
                  newRetractionInfo,
                ),
              ),
            )
          }
        }
      }
    }

    fetchData()
  }, [enforcementRequests])

  useEffect(() => {
    const fetchData = async () => {
      if (!enforcementRequests) return
      for (const request of enforcementRequests) {
        const response = await getJiraMetadata({
          variables: {
            enforcement_request_id: request.id,
          },
        })

        let isActive = false
        if (response.data) {
          const jiraMetadata = response.data.jira_metadata
          isActive = jiraMetadata.reduce((active, { has_platform_reply, status }) => {
            active =
              active || (has_platform_reply && status === JIRA_TICKET_STATUS.TO_DO)
            return active
          }, false)

          setEnforcementRequestActiveStatus(
            new Map(enforcementRequestActiveStatus.set(request.id, isActive)),
          )
        }
      }
    }

    fetchData()
  }, [enforcementRequests])

  const getColorForEnforcementStatus = (enforcementStatus: ENFORCEMENT_STATUS) => {
    let bgColor = DOPPEL_ENCRYPT_GREEN_SHADE
    switch (enforcementStatus) {
      case ENFORCEMENT_STATUS.REJECTED:
      case ENFORCEMENT_STATUS.BLOCKED:
      case ENFORCEMENT_STATUS.FAILED:
      case ENFORCEMENT_STATUS.CANCELED:
      case ENFORCEMENT_STATUS.RETRACTED:
        bgColor = DOPPEL_BREACH_RED_SHADE
        break
    }
    return bgColor
  }

  const getStateFromStatus = (status, enforcementRequest) => {
    if (status === ENFORCEMENT_STATUS.REPORTED && enforcementRequest.submitted_at)
      return {
        label: ENFORCEMENT_STATUS.REPORTED,
        info: `
        Submitted ${new Date(
          enforcementRequest.submitted_at,
        ).toLocaleDateString()} by ${
          getExternalUser(enforcementRequest?.user, isEmployeeView) || 'unknown'
        }
      `,
      }

    if (
      status === ENFORCEMENT_STATUS.APPROVED &&
      enforcementRequest.status === ENFORCEMENT_STATUS.APPROVED
    ) {
      return {
        label: ENFORCEMENT_STATUS.APPROVED,
        info:
          enforcementRequest.updated_at &&
          `
        Approved on ${new Date(enforcementRequest.updated_at).toLocaleDateString()}
      `,
      }
    }

    if (
      status === ENFORCEMENT_STATUS.REROUTED &&
      enforcementRequest.status === ENFORCEMENT_STATUS.REROUTED
    ) {
      return {
        label: ENFORCEMENT_STATUS.REROUTED,
        info:
          enforcementRequest.updated_at &&
          `
        Rerouted to provider on ${new Date(
          enforcementRequest.updated_at,
        ).toLocaleDateString()}
      `,
      }
    }

    if (status === ENFORCEMENT_STATUS.STAGED) {
      return {
        label: ENFORCEMENT_STATUS.STAGED,
        info: `
        Request created on ${new Date(
          enforcementRequest.created_at,
        ).toLocaleDateString()}
        `,
      }
    }

    return {
      label: status,
    }
  }
  const EnforcementStatusDropdown = ({ enforcementRequest }) => {
    return (
      <EditDropdown
        currentValue={enforcementRequest.status}
        getColorForValues={getColorForEnforcementStatus}
        isForEmployee
        onChange={(newStatus) => {
          updateEnforcementRequestStatus({
            variables: {
              update_enforcement_request_status_input: {
                enforcement_request_id: enforcementRequest.id,
                enforcement_request_status: newStatus as ENFORCEMENT_STATUS,
                source: 'ui',
              },
            },
          })
          delayRefetchedQueries(client, ['GetEnforcementRequestsForSpoofingReport'])
        }}
        values={[
          ENFORCEMENT_STATUS.APPROVED,
          ENFORCEMENT_STATUS.REROUTED,
          ENFORCEMENT_STATUS.REPORTED,
          ENFORCEMENT_STATUS.REJECTED,
          ENFORCEMENT_STATUS.BLOCKED,
          // Only allow canceling staged requests
          ...(enforcementRequest.status === ENFORCEMENT_STATUS.STAGED
            ? [ENFORCEMENT_STATUS.CANCELED]
            : []),
          // Only allow retracting retractions that have been sent
          ...(enforcementRequest.status === ENFORCEMENT_STATUS.RETRACTION_SENT
            ? [ENFORCEMENT_STATUS.RETRACTED]
            : []),
        ]}
      />
    )
  }

  function getRetractionProgressStates(enforcementRequest): ProgressState[] {
    const retractionHistoryInfo = enforcementRequestsStateBeforeRetraction.get(
      enforcementRequest.id,
    )
    return [
      {
        label: retractionHistoryInfo?.old_status ?? ENFORCEMENT_STATUS.REPORTED,
      },
      {
        label: RETRACTION_SENT_PROGRESS_BAR_TEXT,
        info: retractionHistoryInfo?.retraction_sent_time
          ? `Retraction sent on ${retractionHistoryInfo?.retraction_sent_time.toLocaleDateString()}`
          : '',
      },
      {
        label: ENFORCEMENT_STATUS.RETRACTED,
        info:
          enforcementRequest.status === ENFORCEMENT_STATUS.RETRACTED &&
          enforcementRequest.updated_at &&
          `
            Retracted on ${new Date(enforcementRequest.updated_at).toLocaleDateString()}
          `,
      },
    ]
  }

  const EnforcementProgressBar = ({ enforcementRequest }) => {
    let states: ProgressState[] = POSITIVE_ENFORCEMENT_STATUSES.map((status) => {
      return getStateFromStatus(status, enforcementRequest)
    })
    // if status is negative, override positive state position
    const negPos = NEGATIVE_ENFORCEMENT_STATUSES.indexOf(enforcementRequest.status)

    if (negPos > -1) {
      const message = `${
        enforcementRequest.status.charAt(0).toUpperCase() +
        enforcementRequest.status.slice(1)
      } on ${new Date(enforcementRequest.updated_at).toLocaleDateString()}`
      const reason = enforcementRequest.reason
        ? ` due to ${enforcementRequest.reason}`
        : ''
      states[Math.min(negPos, PROGRESS_BAR_LENGTH - 1)] = {
        label: enforcementRequest.status,
        info: message + reason,
      }
    } else {
      const posPos = POSITIVE_ENFORCEMENT_STATUSES.indexOf(enforcementRequest.status)
      if (posPos > -1) {
        states[Math.min(posPos, PROGRESS_BAR_LENGTH - 1)] = getStateFromStatus(
          enforcementRequest.status,
          enforcementRequest,
        )
      }
    }

    if (RETRACTION_ENFORCEMENT_STATUSES.includes(enforcementRequest.status)) {
      states = getRetractionProgressStates(enforcementRequest)
    }
    const truncated_states = states.slice(0, PROGRESS_BAR_LENGTH)

    return (
      <ProgressBar
        currentState={
          enforcementRequest.status === ENFORCEMENT_STATUS.RETRACTION_SENT
            ? RETRACTION_SENT_PROGRESS_BAR_TEXT
            : enforcementRequest.status
        }
        isNegative={negPos > -1}
        states={truncated_states}
      />
    )
  }

  const sortFunction = (a, b) => {
    const keyA = new Date(a.updated_at)
    const keyB = new Date(b.updated_at)
    // Compare the 2 dates
    if (keyA < keyB) return 1
    if (keyA > keyB) return -1
    return 0
  }
  const renderPlatformInformation = (enforcementRequest) => {
    if (enforcementRequest.submission_external_id) {
      const handleCopyClick = (event) => {
        event.stopPropagation()

        if (!navigator.clipboard) {
          toast({
            title: 'Failed to copy',
            description: "Browser doesn't support clipboard functionality",
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
          return
        }

        navigator.clipboard
          .writeText(enforcementRequest.submission_external_id)
          .then(() => {
            toast({
              title: 'Copied',
              description: 'External Submission ID copied to clipboard',
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
          })
          .catch(() => {
            toast({
              title: 'Failed to copy',
              description: 'Unexpected error',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          })
      }

      return (
        <Tooltip label={'External ID: ' + enforcementRequest.submission_external_id}>
          <Text fontSize={12} onClick={handleCopyClick}>
            {enforcementRequest.platform}
          </Text>
        </Tooltip>
      )
    }
    if (enforcementRequest.submission_proof_url) {
      if (isEmployeeView) {
        return (
          <Tooltip
            label={'Submission Proof: ' + enforcementRequest.submission_proof_url}
          >
            <DoppelLink
              fontSize={12}
              href={enforcementRequest.submission_proof_url}
              minWidth={0}
              name={enforcementRequest.platform}
            />
          </Tooltip>
        )
      } else {
        return (
          <Tooltip label={'Submitted'}>
            <Text fontSize={12}>{enforcementRequest.platform}</Text>
          </Tooltip>
        )
      }
    }
    return <Text fontSize={12}>{enforcementRequest.platform}</Text>
  }

  function EnforcementStatus(enforcementRequest, moreThanOneEnforcement = false) {
    if (!isEmployeeView && enforcementRequest.status === ENFORCEMENT_STATUS.CANCELED) {
      return <></>
    }

    return (
      <Flex alignItems="left" justify="space-between" pb={1} pt={1} w="400px">
        <HStack minWidth="240px" spacing={2}>
          <Tooltip label={enforcementRequest.type}>
            <span>{ENFORCEMENT_TYPE_TO_ICON[enforcementRequest.type]}</span>
          </Tooltip>

          {renderPlatformInformation(enforcementRequest)}

          <EnforcementProgressBar enforcementRequest={enforcementRequest} />

          <EnforcementStatusDropdown enforcementRequest={enforcementRequest} />

          <DeleteButton
            deleteFunc={() => {
              setEnforcementRequestIdToDelete(enforcementRequest.id)
              onOpen()
            }}
          />

          {setRetractionFormState &&
            (enforcementRequest.status === ENFORCEMENT_STATUS.REPORTED ||
              enforcementRequest.status === ENFORCEMENT_STATUS.APPROVED) && (
              <RetractionButton
                retractionFunc={() => {
                  setRetractionFormState({
                    isOpen: true,
                    enforcementRequest: enforcementRequest,
                  })
                }}
              />
            )}

          {RETRACTION_ENFORCEMENT_STATUSES.includes(enforcementRequest.status) &&
            enforcementRequest.retraction_proof_url && (
              <RetractionScreenshotViewButton
                screenshotURL={enforcementRequest.retraction_proof_url}
              />
            )}

          {isEmployeeView && enforcementRequestActiveStatus.get(enforcementRequest.id) && (
            <Tooltip
              label={
                'This enforcement request has an active ticket in Jira. Please follow up.'
              }
            >
              <Icon as={WarningIcon} color={DOPPEL_BREACH_RED_SHADE} fontSize={12} />
            </Tooltip>
          )}

          {moreThanOneEnforcement && (
            <h2>
              <AccordionButton>
                <AccordionIcon />
              </AccordionButton>
            </h2>
          )}
        </HStack>
      </Flex>
    )
  }

  const renderEnforcementRequests = () => {
    if (
      !enforcementRequests &&
      !enforcementRequests.length &&
      screenshots &&
      !Object.entries(screenshots).length
    )
      return <></>
    const sortedEnforcementRequests = [...enforcementRequests].sort(sortFunction)
    const groupedEnforcementRequests = sortedEnforcementRequests.reduce((acc, cur) => {
      const platform = cur.platform
      const enforcementType = cur.type
      const combinedPlatformType = `${platform}-${enforcementType}`
      if (acc[combinedPlatformType]) {
        acc[combinedPlatformType].push(cur)
      } else {
        acc[combinedPlatformType] = [cur]
      }
      return acc
    }, {})

    return (
      <Box>
        <Accordion allowToggle>
          {Object.keys(groupedEnforcementRequests)
            .sort()
            .map((platformAndType) => {
              if (!groupedEnforcementRequests[platformAndType]) return <></>
              const enforcementRequest = groupedEnforcementRequests[platformAndType][0]
              const index = 0
              return (
                <AccordionItem key={`enforcement-request-old-${index}`}>
                  <Flex direction="column" pt={1} verticalAlign={'center'}>
                    <Flex direction="row" marginLeft={4}>
                      {EnforcementStatus(
                        enforcementRequest,
                        groupedEnforcementRequests[platformAndType].length > 1,
                      )}
                    </Flex>

                    <AccordionPanel alignItems="left">
                      {groupedEnforcementRequests[platformAndType].map(
                        (enforcementRequest, index) => {
                          if (index === 0) return <></>
                          return EnforcementStatus(enforcementRequest)
                        },
                      )}
                    </AccordionPanel>
                  </Flex>
                </AccordionItem>
              )
            })}
        </Accordion>

        {
          //render v1 screenshots as "reported" progress bar. to be removed soon
          screenshots &&
            Object.entries(screenshots)?.map(([key, value], index) => (
              <Flex
                alignItems="center"
                justify="space-between"
                key={`enforcement-request-old-${index}`}
                marginBottom={1}
              >
                <DoppelLink
                  fontSize={12}
                  href={value as string}
                  name={key}
                ></DoppelLink>

                <Box minWidth="200px">
                  <ProgressBar
                    currentState={ENFORCEMENT_STATUS.REPORTED}
                    states={POSITIVE_ENFORCEMENT_STATUSES.map((status) => {
                      return { label: status }
                    })}
                  />
                </Box>
              </Flex>
            ))
        }
      </Box>
    )
  }

  if (
    isEnforcementRequestLoading ||
    updateEnforcementStatusLoading ||
    deleteEnforcementStatusLoading
  )
    return <Spinner />

  if (!isEmployeeView) {
    enforcementRequests = enforcementRequests.filter(
      (req) => req.status !== ENFORCEMENT_STATUS.CANCELED,
    )
  }

  if (!isEmployeeView && !enforcementRequests.length) return <></>

  return (
    <>
      <Divider maxWidth="90%" />

      <DetailRow
        hasMaxTwoColumns={true}
        leftDetail={
          <>
            <VStack align="flex-start" spacing={1}>
              {renderEnforcementRequests()}

              {
                <HStack>
                  {isEmployeeView && (
                    <Button
                      backgroundColor={DOPPEL_INTERNAL_PURPLE}
                      onClick={onEnforcementUpload}
                      rightIcon={<IoMdAdd />}
                      size="xs"
                      textColor={DOPPEL_WHITE}
                    >
                      Upload
                    </Button>
                  )}

                  {DETAILS_ENABLED_PRODUCTS.includes(product) && (
                    <DoppelDefaultButton
                      onClick={() => setShouldShowEnforcementDetails(true)}
                      size="xs"
                      textColor={DOPPEL_WHITE}
                    >
                      Show Details
                    </DoppelDefaultButton>
                  )}
                </HStack>
              }
            </VStack>
          </>
        }
        title="Enforcements"
      />

      <DoppelAlertDialog
        body={`Are you sure you want to delete this enforcement request?`}
        cancelRef={cancelRef}
        confirmAction={() => {
          deleteEnforcementRequest({
            variables: {
              id: enforcementRequestIdToDelete,
            },
          })
          delayRefetchedQueries(client, ['GetEnforcementRequestsForSpoofingReport'])
          onClose()
        }}
        header="Confirm Delete Enforcement Request"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
