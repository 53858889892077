import { UnorderedList } from '@chakra-ui/react'
import EnforcementRequestDisplay from '@/components/enforcements/enforcement_request_display'
import { EnforcementPlatformTypeLabels, ProductType } from '@/generated/enums'
import { capitalizeFirstLetter, replaceUnderscoresWithSpaces } from '../string_utils'

export const renderEnforcementRequests = (
  enforcementRequests: any[],
  removeEnforcementOnClick: any,
  product: string,
  rejectEnforcement = true, // true if user can reject enforcement, false if user can add it back
  areSuggestedEnforcements = false, // true if enforcements are suggested enforcements, false otherwise
  reviewEnforcementOnClick: any = null, // function to be called when user clicks on review button
) => {
  return (
    <UnorderedList>
      {enforcementRequests.map((enforcementRequest, pos) => {
        return (
          <EnforcementRequestDisplay
            enforcementRequest={enforcementRequest}
            isSuggestedEnforcement={areSuggestedEnforcements}
            key={`enforcement-request-${pos}`}
            pos={pos}
            product={product}
            rejectEnforcement={rejectEnforcement}
            removeEnforcementOnClick={removeEnforcementOnClick}
            reviewEnforcementOnClick={reviewEnforcementOnClick}
          />
        )
      })}
    </UnorderedList>
  )
}

// Move enforcement request from provided enforcementRequests to moveToEnforcementRequests
export const moveEnforcement = (
  pos,
  enforcementRequests,
  setEnforcementRequests,
  moveToEnforcementRequests,
  setMoveToEnforcementRequests,
  newStatus,
  enforcementToMove = null,
) => {
  const removedEnforcement = enforcementRequests[pos]
  if (enforcementToMove) {
    enforcementToMove.status = newStatus
  } else {
    removedEnforcement.status = newStatus
  }
  setEnforcementRequests((enforcementRequests) => {
    return enforcementRequests.filter((_, i) => i !== pos)
  })
  setMoveToEnforcementRequests([
    ...moveToEnforcementRequests,
    enforcementToMove || removedEnforcement,
  ])
}

export const getReportingPlatformLabel = (product, platform, platform_type) => {
  switch (product) {
    case ProductType.DOMAIN:
      // reporting to platform type (registrar, hosting provide, etc) for domains
      return getDomainsPlatformTypeLabel(platform_type)
    case ProductType.MOBILE_APP:
    case ProductType.SOCIAL_MEDIA:
      // reporting directly to platform for mobile apps and social media
      return replaceUnderscoresWithSpaces(platform)
    default:
      return ''
  }
}

export const getDomainsPlatformTypeLabel = (platform_type) => {
  return EnforcementPlatformTypeLabels[platform_type]
}

export const getEnforcementRequestLabel = (
  enforcementRequest,
  product,
  showingIndividualDetails = false,
) => {
  const enforcementPlatformTypeLabel = getReportingPlatformLabel(
    product,
    enforcementRequest.selected_platform_name,
    enforcementRequest.platform_type,
  )
  const cleanedEnforcementType = replaceUnderscoresWithSpaces(
    enforcementRequest.enforcement_type,
  )
  const baseEnforcementRequestLabel = showingIndividualDetails
    ? `${cleanedEnforcementType} enforcement to ${enforcementPlatformTypeLabel}`
    : `${capitalizeFirstLetter(
        replaceUnderscoresWithSpaces(enforcementRequest.enforcement_type),
      )} to ${enforcementPlatformTypeLabel}`
  if (enforcementRequest.content_type_display_name) {
    return `${baseEnforcementRequestLabel} for ${enforcementRequest.content_type_display_name}`
  } else {
    return baseEnforcementRequestLabel
  }
}
