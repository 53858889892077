import {
  EnforcementMethod,
  EnforcementRequestPlatform,
  EnforcementType,
  PlatformName,
  ProductType,
  SuggestedEnforcementDisabledReason,
} from '@/generated/enums'
import { SuggestedEnforcement } from './suggested_enforcement_types'
import { TRADEMARK_ID } from '@/components/enforcements/enforcement_user_input'
import {
  AUTO_ENFORCEMENT_METHODS,
  DISABLED_REASON_TO_DISABLED_INFO,
  NON_MULTIPLE_REPORTING_PLATFORMS,
  SOCIAL_MEDIA_PLATFORMS_WITH_TEMPLATES,
} from './suggested_enforcement_constants'
import { SUPPORTED_API_ENFORCEMENT_OPTIONS } from '../constants'
import { NEGATIVE_ENFORCEMENT_STATUSES } from '@/components/enforcements/enforcement_statuses'

export const getFilteredEnforcementMethods = (
  suggestedEnforcement: SuggestedEnforcement,
  product,
) => {
  const enforcementMethods = suggestedEnforcement.allowed_enforcement_methods
  if (
    (suggestedEnforcement.enforcement_type === EnforcementType.COPYRIGHT &&
      product === ProductType.DOMAIN) ||
    (suggestedEnforcement.enforcement_type === EnforcementType.TRADEMARK &&
      suggestedEnforcement.user_input?.[TRADEMARK_ID] === '') ||
    (product === ProductType.SOCIAL_MEDIA &&
      !SOCIAL_MEDIA_PLATFORMS_WITH_TEMPLATES.includes(
        suggestedEnforcement.provider_name as PlatformName,
      ))
  ) {
    return enforcementMethods.filter(
      (enforcementMethod) =>
        !AUTO_ENFORCEMENT_METHODS.includes(enforcementMethod.method_type),
    )
  } else if (
    !SUPPORTED_API_ENFORCEMENT_OPTIONS.some(
      ([supportedPlatform, supportedType]) =>
        supportedPlatform === suggestedEnforcement.provider_name &&
        supportedType === suggestedEnforcement.enforcement_type,
    )
  ) {
    return enforcementMethods.filter(
      (enforcementMethod) => enforcementMethod.method_type !== EnforcementMethod.API,
    )
  }

  return enforcementMethods
}

const checkEnforcements = (
  suggestedEnforcement: SuggestedEnforcement,
  enforcements: any[],
  actionToPerform,
) => {
  for (const enforcementRequest of enforcements || []) {
    if (
      enforcementRequest.type === suggestedEnforcement.enforcement_type &&
      enforcementRequest.platform === suggestedEnforcement.provider_name
    ) {
      const performedAction = actionToPerform(suggestedEnforcement, enforcementRequest)
      if (performedAction) {
        return performedAction
      }
    }
  }
  return null
}

const checkSingleActiveEnforcement = (
  suggestedEnforcement: SuggestedEnforcement,
  enforcementRequest,
) => {
  if (!NEGATIVE_ENFORCEMENT_STATUSES.includes(enforcementRequest.status)) {
    return SuggestedEnforcementDisabledReason.ACTIVE_ENFORCEMENT_ALREADY_EXISTS
  } else if (
    NON_MULTIPLE_REPORTING_PLATFORMS.includes(
      suggestedEnforcement.provider_name as EnforcementRequestPlatform,
    )
  ) {
    return SuggestedEnforcementDisabledReason.MULTIPLE_ENFORCEMENTS_NOT_ALLOWED
  }
  return null
}

const checkActiveEnforcements = (
  suggestedEnforcement: SuggestedEnforcement,
  spoofReport,
) => {
  return checkEnforcements(
    suggestedEnforcement,
    spoofReport?.enforcement_requests,
    checkSingleActiveEnforcement,
  )
}

const checkSingleManuallyStagedEnforcement = (
  suggestedEnforcement: SuggestedEnforcement,
  enforcementRequest,
) => {
  return SuggestedEnforcementDisabledReason.ALREADY_STAGED_IN_DOMAINS_TAKEDOWN_FORM
}

const checkManuallyStagedEnforcements = (
  suggestedEnforcement: SuggestedEnforcement,
  stagedEnforcements,
) => {
  return checkEnforcements(
    suggestedEnforcement,
    stagedEnforcements,
    checkSingleManuallyStagedEnforcement,
  )
}

const checkSuggestedEnforcementsStagedEnforcements = (
  suggestedEnforcement: SuggestedEnforcement,
  stagedSuggestedEnforcements,
) => {
  for (const stagedSuggestedEnforcement of stagedSuggestedEnforcements || []) {
    if (
      stagedSuggestedEnforcement.enforcement_type ===
        suggestedEnforcement.enforcement_type &&
      stagedSuggestedEnforcement.provider_name === suggestedEnforcement.provider_name
    ) {
      return SuggestedEnforcementDisabledReason.ALREADY_STAGED_IN_SUGGESTED_ENFORCEMENTS
    }
  }
  return null
}

const getActiveOrStagedDisabledReason = (
  suggestedEnforcement: SuggestedEnforcement,
  spoofReport,
  stagedEnforcements,
  stagedSuggestedEnforcements,
) => {
  const activeEnforcement = checkActiveEnforcements(suggestedEnforcement, spoofReport)
  if (activeEnforcement) {
    return activeEnforcement
  }
  const stagedEnforcement = checkManuallyStagedEnforcements(
    suggestedEnforcement,
    stagedEnforcements,
  )
  if (stagedEnforcement) {
    return stagedEnforcement
  }
  const stagedSuggestedEnforcement = checkSuggestedEnforcementsStagedEnforcements(
    suggestedEnforcement,
    stagedSuggestedEnforcements,
  )
  if (stagedSuggestedEnforcement) {
    return stagedSuggestedEnforcement
  }
  return null
}

export const getActiveOrStagedDisabledReasonLabel = (
  suggestedEnforcement: SuggestedEnforcement,
  spoofReport,
  stagedEnforcements,
  stagedSuggestedEnforcements,
) => {
  const reason = getActiveOrStagedDisabledReason(
    suggestedEnforcement,
    spoofReport,
    stagedEnforcements,
    stagedSuggestedEnforcements,
  )
  if (reason) {
    return `${reason} ${DISABLED_REASON_TO_DISABLED_INFO[reason].requiredAction}`
  } else {
    return null
  }
}
