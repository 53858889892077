import { SUBMISSION_EMAIL } from '@/components/enforcements/enforcement_user_input'
import { EMAIL_ENFORCEMENT_METHODS } from './suggested_enforcement_constants'
import {
  EnforcementMethodTuple,
  SuggestedEnforcement,
} from './suggested_enforcement_types'
import { getFilteredEnforcementMethods } from './post_process_suggested_enforcement_utils'

export const updateSelectedMethodData = (
  suggestedEnforcement: SuggestedEnforcement,
  newMethodData,
): SuggestedEnforcement => {
  return {
    ...suggestedEnforcement,
    selected_method: {
      ...suggestedEnforcement.selected_method,
      method_data: newMethodData,
    },
  }
}

export const updateSelectedMethod = (
  suggestedEnforcement: SuggestedEnforcement,
  newMethod: EnforcementMethodTuple,
): SuggestedEnforcement => {
  const updatedSuggestedEnforcement = {
    ...suggestedEnforcement,
    selected_method: newMethod,
  }
  return updateUserInputWithSubmissionEmail(
    updatedSuggestedEnforcement,
    newMethod.method_data,
  )
}

export const updateSuggestedEnforcementViaPlatformPicker = (
  suggestedEnforcement: SuggestedEnforcement,
  {
    product,
    providerName,
    selectedPlatformName,
    allowedEnforcementMethods,
    notes,
    selectedPlatformSelectionMethod,
  },
): SuggestedEnforcement => {
  const initialEnforcement = {
    ...suggestedEnforcement,
    provider_name: providerName,
    selected_platform_name: selectedPlatformName,
    allowed_enforcement_methods: allowedEnforcementMethods,
    notes: notes,
    selected_platform_selection_method: selectedPlatformSelectionMethod,
  }
  const filteredEnforcementMethods = getFilteredEnforcementMethods(
    initialEnforcement,
    product,
  )
  const selectedMethod = filteredEnforcementMethods[0]
  initialEnforcement.allowed_enforcement_methods = filteredEnforcementMethods
  initialEnforcement.selected_method = selectedMethod
  return updateUserInputWithSubmissionEmail(
    initialEnforcement,
    selectedMethod.method_data,
  )
}

export const updateUserInputWithSubmissionEmail = (
  suggestedEnforcement: SuggestedEnforcement,
  newEmail,
): SuggestedEnforcement => {
  const suggestedEnforcementMethodType =
    suggestedEnforcement.selected_method.method_type
  const submissionEmailUserInput = {
    ...(EMAIL_ENFORCEMENT_METHODS.includes(suggestedEnforcementMethodType)
      ? { [SUBMISSION_EMAIL]: newEmail }
      : {}),
  }
  return updateUserInput(suggestedEnforcement, submissionEmailUserInput)
}

export const updateUserInput = (
  suggestedEnforcement: SuggestedEnforcement,
  newUserInput,
): SuggestedEnforcement => {
  return {
    ...suggestedEnforcement,
    user_input: {
      ...suggestedEnforcement.user_input,
      ...newUserInput,
    },
  }
}

export const updateFile = (
  suggestedEnforcement: SuggestedEnforcement,
  newFile,
): SuggestedEnforcement => {
  return updateSingleSuggestedEnforcementField(suggestedEnforcement, 'file', newFile)
}

const updateSingleSuggestedEnforcementField = (
  suggestedEnforcement: SuggestedEnforcement,
  fieldName,
  newValue,
): SuggestedEnforcement => {
  return {
    ...suggestedEnforcement,
    [fieldName]: newValue,
  }
}
