import { IconButton } from '@chakra-ui/react'
import { IoMdClose } from 'react-icons/io'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../utils/style'

const DeleteButton = ({ deleteFunc }) => {
  const [isEmployeeView] = useIsEmployeeView()

  if (!isEmployeeView) return <></>

  return (
    <IconButton
      aria-label="delete button"
      bgColor={DOPPEL_INTERNAL_PURPLE}
      color={DOPPEL_WHITE}
      icon={<IoMdClose />}
      onClick={() => {
        deleteFunc()
      }}
      size={'xs'}
    ></IconButton>
  )
}

export default DeleteButton
