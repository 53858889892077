import { EnforcementPlatformType, PlatformSelectionMethod } from '@/generated/enums'
import {
  useGetEnforcementPlatformNamesQuery,
  useGetEnforcementPlatformsWithTypesLazyQuery,
} from '@/generated/graphql'
import DropdownMenu from '../shared/forms/dropdown_menu'
import { DEFAULT_ENFORCEMENT_METHODS } from '@/utils/suggested_enforcements/suggested_enforcement_constants'
import { createEnforcementMethodsFromEnforcementCategories } from '@/utils/suggested_enforcements/create_suggested_enforcements_utils'

export default function EnforcementPlatformMenu({
  setSuggestedEnforcementViaPlatformPicker,
  suggestedEnforcement,
  product,
}) {
  const [fetchQuery] = useGetEnforcementPlatformsWithTypesLazyQuery()
  const enforcementTypesBoolExp = {
    _and: [
      { stage: { _eq: 'initial' } },
      { type: { _eq: suggestedEnforcement.enforcement_type } },
    ],
  }
  const enforcementPlatformsWhere: any = {
    _and: [{ enforcement_categories: enforcementTypesBoolExp }], // Want to filter to ensure there is at least one matching enforcement_type entry for the enf type and initial stage
  }

  if (suggestedEnforcement.platform_type === EnforcementPlatformType.UNKNOWN_TLD) {
    enforcementPlatformsWhere._and.push({
      _or: [
        {
          types: { _contains: EnforcementPlatformType.COUNTRY_CODE_TLD },
        },
        {
          types: { _contains: EnforcementPlatformType.GENERIC_TLD },
        },
      ],
    })
  } else {
    enforcementPlatformsWhere._and.push({
      types: { _contains: suggestedEnforcement.platform_type },
    })
  }
  const { data } = useGetEnforcementPlatformNamesQuery({
    variables: {
      enforcementPlatformsWhere: enforcementPlatformsWhere,
    },
  })

  const enforcementPlatforms = data?.enforcement_platforms || []

  return (
    <DropdownMenu
      allowQueryAsCustomOption={true}
      buttonDisplayFunction={([selectedItem]) => selectedItem ?? 'Select Platform'}
      isMultiSelect={false}
      items={enforcementPlatforms.map((platform) => platform.name)}
      placeholder="Search platform..."
      selectedItems={[suggestedEnforcement.selected_platform_name]}
      setSelectedItems={([name]) => {
        if (!name) {
          // if the user clears the selection
          setSuggestedEnforcementViaPlatformPicker({
            product: product,
            providerName: '',
            selectedPlatformName: '',
            allowedEnforcementMethods: DEFAULT_ENFORCEMENT_METHODS,
            notes: null,
            selectedPlatformSelectionMethod: PlatformSelectionMethod.UNKNOWN,
          })
          return
        }
        fetchQuery({
          variables: {
            limit: 1,
            enforcementPlatformsWhere: { name: { _eq: name } },
            enforcementTypesWhere: enforcementTypesBoolExp,
          },
        }).then((response) => {
          if (response.data && response.data.enforcement_platforms.length > 0) {
            const platform = response.data.enforcement_platforms[0]
            setSuggestedEnforcementViaPlatformPicker({
              product: product,
              providerName: platform.platform,
              selectedPlatformName: platform.name,
              allowedEnforcementMethods:
                createEnforcementMethodsFromEnforcementCategories(
                  platform.enforcement_categories,
                ),
              notes: platform.notes,
              selectedPlatformSelectionMethod: PlatformSelectionMethod.PLATFORM_PICKER,
            })
          } else {
            setSuggestedEnforcementViaPlatformPicker({
              product: product,
              providerName: name,
              selectedPlatformName: name,
              allowedEnforcementMethods: DEFAULT_ENFORCEMENT_METHODS,
              notes: '',
              selectedPlatformSelectionMethod: PlatformSelectionMethod.CUSTOM,
            })
          }
        })
      }}
      showSearchBar={true}
    />
  )
}
