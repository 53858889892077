import { Spoof_Matches } from '../../generated/graphql'
import { useIsEmployeeView, useOrgID } from '../../hooks/id_token_claims'
import DoppelLink from '../report_detail/doppel_link'
import { getSocialMediaDataUrl } from '../../utils/domain_utils'
import { shouldDefangLinks, shouldDefaultToHttp } from '../../utils/feature_flags'
import { LinkProps } from '@chakra-ui/react'

type Props = {
  match: Spoof_Matches
} & LinkProps

const SpoofMatch: React.FC<Props> = ({ match, ...rest }) => {
  const [isEmployeeView] = useIsEmployeeView()
  const orgId = useOrgID()
  const spoofMatchUrl = getSpoofMatchUrl(match)

  return (
    <DoppelLink
      disableLink={true}
      href={spoofMatchUrl}
      isDefanged={!isEmployeeView && shouldDefangLinks(orgId)}
      name={spoofMatchUrl}
      noOfLines={1}
      shouldDefaultToHttp={shouldDefaultToHttp(orgId)}
      {...rest}
    />
  )
}

export function getSpoofMatchUrl(match?: any) {
  if (!match) return null

  if (match.full_url) return match.full_url.unsanitized_url || match.full_url.url
  if (match.social_media_data) {
    return getSocialMediaDataUrl(
      match.social_media_data.platform_name,
      match.social_media_data.slug,
    )
  }
  if (match.social_media_post) return match.social_media_post.url
  if (match.ecommerce_listing) return match.ecommerce_listing.url
  if (match.mobile_app) return match.mobile_app.link
  if (match.phone_number) return match.phone_number.number
}

export function getSpoofMatchScreenshotUrl(match?: any) {
  if (!match) return null

  if (match.full_url) return match.full_url.screenshot_url
  if (match.social_media_data) {
    return match.social_media_data.profile_image_url
  }
  if (match.mobile_app) {
    if (match.mobile_app.screenshot_url) {
      return match.mobile_app.screenshot_url
    } else {
      return match.mobile_app.thumbnail_url
    }
  }
}

export default SpoofMatch
