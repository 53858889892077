import {
  SuggestedEnforcementDisabledReason,
  SuggestedEnforcementDisabledAction,
  PlatformSelectionMethod,
  EnforcementMethod,
  PlatformName,
  EnforcementRequestPlatform,
  EnforcementUrlType,
} from '@/generated/enums'
import { EnforcementMethodTuple } from './suggested_enforcement_types'

export const DISABLED_REASON_TO_DISABLED_INFO = {
  [SuggestedEnforcementDisabledReason.DEDUPE_TLD]: {
    requiredAction: SuggestedEnforcementDisabledAction.NO_ACTION,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.UNSUPPORTED_ENFORCEMENT_TYPES]: {
    requiredAction: SuggestedEnforcementDisabledAction.NO_ACTION,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.ACTIVE_ENFORCEMENT_ALREADY_EXISTS]: {
    requiredAction: SuggestedEnforcementDisabledAction.BLOCK_AND_REREPORT,
    shouldDisplayInitially: true,
  },
  [SuggestedEnforcementDisabledReason.UNSUPPORTED_TLD]: {
    requiredAction: SuggestedEnforcementDisabledAction.NO_ACTION,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.ALREADY_STAGED_IN_DOMAINS_TAKEDOWN_FORM]: {
    requiredAction: SuggestedEnforcementDisabledAction.AVOID_DUPLICATION,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.FLAGGED_URL_HAS_PATH]: {
    requiredAction: SuggestedEnforcementDisabledAction.REPORT_TO_FLAGGED_URL_HOST,
    shouldDisplayInitially: true,
  },
  [SuggestedEnforcementDisabledReason.ENFORCEMENT_TYPE_NOT_PHISHING]: {
    requiredAction: SuggestedEnforcementDisabledAction.RESUBMIT_FORM_WITH_PHISHING,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.ENFORCEMENT_METADATA_NO_LEGAL_NAME]: {
    requiredAction: SuggestedEnforcementDisabledAction.ADD_ORG_LEGAL_NAME,
    shouldDisplayInitially: true,
  },
  [SuggestedEnforcementDisabledReason.FLAGGED_URL_HAS_PLATFORM]: {
    requiredAction: SuggestedEnforcementDisabledAction.REPORT_TO_FLAGGED_URL_HOST,
    shouldDisplayInitially: true,
  },
  [SuggestedEnforcementDisabledReason.DISABLED_HOST_OR_REGISTRAR]: {
    requiredAction: SuggestedEnforcementDisabledAction.REPORT_TO_FLAGGED_URL_HOST,
    shouldDisplayInitially: true,
  },
  [SuggestedEnforcementDisabledReason.NOT_CRYPTO_CUSTOMER]: {
    requiredAction: SuggestedEnforcementDisabledAction.SWITCH_CUSTOMER_TYPE,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.URL_TYPE_NOT_MALICIOUS]: {
    requiredAction: SuggestedEnforcementDisabledAction.RESUBMIT_FORM_WITH_MALICIOUS,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.IP_ADDRESS]: {
    requiredAction: SuggestedEnforcementDisabledAction.NO_ACTION,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.CRYPTO_WALLET_BLOCKING_NOT_SELECTED]: {
    requiredAction:
      SuggestedEnforcementDisabledAction.RESUBMIT_FORM_WITH_CRYPTO_WALLET_BLOCKING,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.NOT_CLEANDNS_SUPPORTED]: {
    requiredAction: SuggestedEnforcementDisabledAction.NO_ACTION,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.ALREADY_CREATED_CLEANDNS_ENFORCEMENT]: {
    requiredAction: SuggestedEnforcementDisabledAction.NO_ACTION,
    shouldDisplayInitially: true,
  },
  [SuggestedEnforcementDisabledReason.URL_TYPE_NOT_SUPPORTED]: {
    requiredAction: SuggestedEnforcementDisabledAction.RESUBMIT_FORM_WITH_URL_TYPE,
    shouldDisplayInitially: false,
  },
  [SuggestedEnforcementDisabledReason.MULTIPLE_ENFORCEMENTS_NOT_ALLOWED]: {
    requiredAction: SuggestedEnforcementDisabledAction.FOLLOWUP_ORIGINAL,
    shouldDisplayInitially: true,
  },
  [SuggestedEnforcementDisabledReason.ALREADY_STAGED_IN_SUGGESTED_ENFORCEMENTS]: {
    requiredAction: SuggestedEnforcementDisabledAction.AVOID_DUPLICATION,
    shouldDisplayInitially: false,
  },
}

export const PLATFORM_FOUND_SELECTION_METHODS = [
  PlatformSelectionMethod.PLATFORM_KNOWN,
  PlatformSelectionMethod.MATCHED_STRING,
]

export const UNKNOWN_PLATFORM_PREFIX = 'unknown_' // Prefix to display name for for unknown platforms

export const EMAIL_ENFORCEMENT_METHODS = [
  EnforcementMethod.MANUAL_EMAIL,
  EnforcementMethod.AUTO_EMAIL,
]
export const SOCIAL_MEDIA_PLATFORMS_WITH_TEMPLATES = [
  PlatformName.FACEBOOK,
  PlatformName.INSTAGRAM,
  PlatformName.TWITTER,
]

export const AUTO_ENFORCEMENT_METHODS = [
  EnforcementMethod.AUTO_EMAIL,
  EnforcementMethod.AUTO_FORM,
  EnforcementMethod.API,
  EnforcementMethod.OTHER,
]

export const DEFAULT_ENFORCEMENT_METHODS: EnforcementMethodTuple[] = [
  {
    method_type: EnforcementMethod.AUTO_EMAIL,
    method_data: null,
  },
  {
    method_type: EnforcementMethod.MANUAL_FORM,
    method_data: null,
  },
  {
    method_type: EnforcementMethod.MANUAL_EMAIL,
    method_data: null,
  },
]

export const NON_MULTIPLE_REPORTING_PLATFORMS = [
  EnforcementRequestPlatform.GOOGLE,
  EnforcementRequestPlatform.CLOUDFLARE,
  EnforcementRequestPlatform.METAMASK,
  EnforcementRequestPlatform.COINBASE,
  EnforcementRequestPlatform.PHANTOM,
]

export const CRYPTO_WALLET_BLOCKING_ENABLED_URL_TYPES = [
  EnforcementUrlType.MALICIOUS,
  EnforcementUrlType.BUILDER,
]
