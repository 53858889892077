import { HStack, Flex, Switch, FormControl, Text, Spacer } from '@chakra-ui/react'
import {
  getFlaggedPreviewImage,
  getFlaggedPreviewImageBoxSize,
  WEB2_SCREENSHOTS_BUCKET,
} from '../../../utils/web2'
import ImagePreviewSection from '../../report_detail/image_preview_section'
import { useSelectedSpoofReport } from '../../../hooks/spoof_report_table_filters'
import SpoofRequestManualScreenshotButton from '../spoof_request_manual_screenshot_button'
import SpoofReportScreenshotVersioningButton from '../spoof_report_screenshot_versioning_button'
import { useEffect, useState } from 'react'
import SpoofDetailUploadScreenshotForm from './spoof_detail_upload_screenshot_form'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import DoppelFormLabel from '@/components/shared/forms/doppel_form_label'
import { ProductType, ReportStatus } from '@/generated/enums'
import { useGetReportMatchesLazyQuery } from '@/generated/graphql'
import { getVersionedScreenshotName } from '@/utils/reports/report_utils'

const SpoofDetailImagePreview = ({ isCondensedView = false }) => {
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [isScreenshotUploadFormOpen, setIsScreenshotUploadFormOpen] = useState(false)
  const [selectedMatchIndex, setSelectedMatchIndex] = useState(0)
  const [isEmployeeView] = useIsEmployeeView()
  const [shouldShowDelisted, setShouldShowDelisted] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [shouldShowDelistSwitch, setShouldShowDelistSwitch] = useState(false)
  const [matches, setMatches] = useState([])
  const productType = selectedSpoofReport?.platform?.product
  const isTelco = productType === ProductType.TELCO
  const [screenshotDate, setScreenshotDate] = useState(null)
  const [screenshotName, setScreenshotName] = useState(null)
  const [updateScreenshots, setUpdateScreenshots] = useState(false)

  const [
    getReportMatchData,
    { data: reportMatchesData, refetch: refetchReportMatchesData },
  ] = useGetReportMatchesLazyQuery({
    variables: {
      report_id: selectedSpoofReport?.id,
    },
  })

  useEffect(() => {
    if (!selectedSpoofReport) {
      setMatches([])
      setShouldShowDelistSwitch(false)
      return
    }

    getReportMatchData({
      variables: {
        report_id: selectedSpoofReport.id,
      },
    })

    setShouldShowDelistSwitch(
      isEmployeeView && selectedSpoofReport?.report_status === ReportStatus.RESOLVED,
    )

    setScreenshotName(
      getVersionedScreenshotName(selectedSpoofReport, WEB2_SCREENSHOTS_BUCKET),
    )
  }, [selectedSpoofReport, isEmployeeView])

  useEffect(() => {
    setShouldShowDelistSwitch(
      isEmployeeView &&
        selectedSpoofReport?.report_status === ReportStatus.RESOLVED &&
        matches.length > 0,
    )
  }, [isEmployeeView, selectedSpoofReport, matches])

  useEffect(() => {
    const delistedImage =
      matches.length > 0
        ? `https://storage.googleapis.com/delist_screenshots/${matches[selectedMatchIndex]?.id}_delist`
        : null
    setImageUrl(
      shouldShowDelisted ? delistedImage : getFlaggedPreviewImage(selectedSpoofReport),
    )
  }, [shouldShowDelisted, matches, selectedMatchIndex])

  useEffect(() => {
    if (reportMatchesData) {
      setMatches(reportMatchesData.spoof_matches)
    }
  }, [reportMatchesData])

  if (!selectedSpoofReport) return null

  const screenshotVersioningInfo = () => {
    return (
      <Flex width="385px">
        <HStack spacing={2} width="100%">
          <Spacer />

          {screenshotName && (
            <>
              <Text fontSize={12}>{screenshotDate}</Text>

              <SpoofReportScreenshotVersioningButton
                productType={productType}
                screenshotName={screenshotName}
                setImageUrl={setImageUrl}
                setScreenshotDate={setScreenshotDate}
                setUpdateScreenshots={setUpdateScreenshots}
                updateScreenshots={updateScreenshots}
              />
            </>
          )}

          {!isCondensedView && (
            <SpoofRequestManualScreenshotButton
              isScreenshotUploadFormOpen={isScreenshotUploadFormOpen}
              setIsScreenshotUploadFormOpen={setIsScreenshotUploadFormOpen}
            />
          )}
        </HStack>
      </Flex>
    )
  }

  const renderScreenshotOrUploadForm = () => {
    if (isScreenshotUploadFormOpen) {
      return (
        <SpoofDetailUploadScreenshotForm
          onCompleted={() => {
            setIsScreenshotUploadFormOpen(false)
            refetchReportMatchesData()
          }}
          screenshotName={screenshotName}
          selectedSpoofReport={selectedSpoofReport}
          setImageUrl={setImageUrl}
          setUpdateScreenshots={setUpdateScreenshots}
        />
      )
    }

    return (
      <Flex direction="column" width="450px">
        <ImagePreviewSection
          icon_url="https://storage.googleapis.com/doppel_brand_assets/doppel_alert_icon.png"
          imageUrl={imageUrl}
          image_box_size={getFlaggedPreviewImageBoxSize(selectedSpoofReport)}
          is_flagged={true}
          matchAndIndexSelector={{
            spoofMatches: matches ?? [],
            selectedMatchIndex,
            setSelectedMatchIndex,
          }}
          setShouldShowDelisted={setShouldShowDelisted}
          shouldShowDelisted={shouldShowDelisted}
          shouldShowMatchScreenshots={isEmployeeView}
          subtitle="Matched Entity"
          title={selectedSpoofReport.unsanitized_url || selectedSpoofReport.flagged_url}
          width="450px"
        />

        {screenshotVersioningInfo()}

        {shouldShowDelistSwitch && (
          <Flex width="450px">
            <FormControl>
              <HStack>
                <Switch
                  isChecked={shouldShowDelisted}
                  onChange={() => {
                    setShouldShowDelisted(!shouldShowDelisted)
                  }}
                  size="sm"
                />

                <DoppelFormLabel
                  fontSize="xs"
                  formLabel={
                    shouldShowDelisted ? 'Taken Down Screenshot' : 'Original Screenshot'
                  }
                  helperText="Switch between the original screenshot and the screenshot of taken down content if available."
                />
              </HStack>
            </FormControl>
          </Flex>
        )}
      </Flex>
    )
  }

  const renderImagePreview = () => {
    return (
      <Flex height={shouldShowDelistSwitch ? '460px' : '450px'} width="450px">
        {renderScreenshotOrUploadForm()}
      </Flex>
    )
  }

  return isCondensedView ? (
    <HStack>{renderImagePreview()}</HStack>
  ) : (
    <>{renderImagePreview()}</>
  )
}

export default SpoofDetailImagePreview
