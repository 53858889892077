import { IconButton, MenuButton } from '@chakra-ui/react'
import { FaHistory } from 'react-icons/fa'
import { ProductType } from '@/generated/enums'
import { useEffect, useState } from 'react'
import { DOPPEL_BUTTON_GREY, DOPPEL_WHITE } from '@/utils/style'
import { useGetGcsObjectsQuery } from '@/generated/graphql'
import DropdownMenu from '../shared/forms/dropdown_menu'
import { WEB2_SCREENSHOTS_BUCKET, BASE_SCREENSHOT_URL } from '@/utils/web2'
import { getTimestampDisplay } from '@/utils/time'

type Props = {
  productType: ProductType
  screenshotName: string
  setImageUrl: any
  setScreenshotDate: any
  updateScreenshots: boolean
  setUpdateScreenshots: any
}

const SpoofReportScreenshotVersioningButton: React.FC<Props> = ({
  screenshotName,
  setImageUrl,
  setScreenshotDate,
  updateScreenshots,
  setUpdateScreenshots,
}) => {
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [versionInfo, setVersionInfo] = useState([])
  const [latestVersion, setLatestVersion] = useState(null)
  const [versionedScreenshotUrl, setVersionedScreenshotUrl] = useState(null)

  useEffect(() => {
    setVersionedScreenshotUrl(`${BASE_SCREENSHOT_URL}/${screenshotName}`)
  }, [screenshotName])

  const processVersionInfo = (items) => {
    return items.slice().map((item, index) => {
      return {
        ...item,
        version_number: (items.length - index).toString(),
        created_at: getTimestampDisplay(item.created_at, 'YYYY-MM-DD hh:mm'),
      }
    })
  }

  const getScreenshotUrl = (generationNumber) => {
    return `${versionedScreenshotUrl}?generation=${generationNumber}`
  }

  const { data: objectsInfo, refetch } = useGetGcsObjectsQuery({
    variables: {
      bucket_name: WEB2_SCREENSHOTS_BUCKET,
      object_name: screenshotName,
    },
    onCompleted: (objectsInfo) => {
      const versionResults = objectsInfo.get_gcs_objects.objects_info.object_versions
      const processedVersions = processVersionInfo(versionResults)
      if (processedVersions) {
        const latestVersion = processedVersions[0]
        setLatestVersion(latestVersion)
        setScreenshotDate(latestVersion['created_at'])
        setSelectedVersion(latestVersion['version_number'])
        if (versionInfo.length > 0 && processedVersions.length != versionInfo.length) {
          setImageUrl(getScreenshotUrl(latestVersion['generation_number']))
        }
        setVersionInfo(processedVersions)
      }
    },
  })

  const handleVersionSelection = (version) => {
    setSelectedVersion(version)
    if (!version) {
      // If no version is selected, select the latest version
      setSelectedVersion(latestVersion['version_number'])
      setScreenshotDate(latestVersion['created_at'])
      setImageUrl(getScreenshotUrl(latestVersion['generation_number']))
    } else {
      const specificVersion = versionInfo.find(
        (item) => item['version_number'] === version,
      )
      setScreenshotDate(specificVersion['created_at'])
      setImageUrl(getScreenshotUrl(specificVersion['generation_number']))
    }
  }

  const getVersionDisplay = (screenshotVersion: string, isSelected: boolean) => {
    const specificVersion = versionInfo.find(
      (item) => item['version_number'] === screenshotVersion,
    )
    const displayText = specificVersion ? `${specificVersion['created_at']}` : ''
    return isSelected ? `${displayText} ✓` : displayText
  }

  const screenshotButton = (
    <MenuButton
      aria-label="Options"
      as={IconButton}
      bgColor={DOPPEL_BUTTON_GREY}
      color={DOPPEL_WHITE}
      icon={<FaHistory />}
      size="xs"
    />
  )

  useEffect(() => {
    if (updateScreenshots) {
      refetch()
      setUpdateScreenshots(false)
    }
  }, [updateScreenshots])

  useEffect(() => {
    refetch()
  }, [screenshotName])

  return (
    <DropdownMenu
      buttonDisplayFunction={(selectedItems) =>
        getVersionDisplay(selectedItems[0], false)
      }
      customButton={screenshotButton}
      disableClearSelection={true}
      icon={<FaHistory size="18" />}
      isMultiSelect={false}
      itemDisplayFunction={(item) => getVersionDisplay(item, item === selectedVersion)}
      items={versionInfo ? versionInfo.map((item) => item['version_number']) : []}
      listWidth="125px"
      placeholder={'Search Screenshots'}
      selectedItems={[selectedVersion ?? null]}
      setSelectedItems={([selectedVersion]) => handleVersionSelection(selectedVersion)}
      w="25px"
    />
  )
}

export default SpoofReportScreenshotVersioningButton
