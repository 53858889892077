import React, { useEffect, useState } from 'react'
import {
  ENFORCEMENT_TYPE,
  MetaReportingContentType,
  SocialMediaPlatform,
} from '../../utils/constants'
import {
  Menu,
  MenuList,
  MenuItem,
  Text,
  MenuButton,
  Button,
  Flex,
  VStack,
  FormControl,
  Icon,
  Input,
  Textarea,
  Box,
} from '@chakra-ui/react'
import { MENU_LIST_ZINDEX } from '../../utils/style'
import { ChevronDownIcon, WarningIcon } from '@chakra-ui/icons'
import DoppelFormLabel from '../shared/forms/doppel_form_label'
import { isSocialMediaPlatformMeta } from '../../utils/enforcement_utils'
import { DomainEnforcementPlatform } from '@/generated/enums'

export const COPYRIGHT_TYPE = 'copyright_type'
export const PROTECTED_SLUG = 'protected_slug'
export const COPYRIGHTED_CONTENT_URL = 'copyrighted_content_url'
export const TRADEMARK_ID = 'trademark_id'
export const YOUTUBE_CONTENT_TYPE = 'youtube_content_type'
export const YOUTUBE_BRAND_TYPE = 'youtube_brand_type'
export const SUBMISSION_EMAIL = 'submission_email'
export const EMAIL_BODY = 'email_body'
export const COPYRIGHT_ORIGINAL_WORK_DESCRIPTION = 'copyright_original_work_description'

const COPYRIGHT_ORIGINAL_WORK_DESCRIPTION_CHAR_LIMIT = 255

export default function EnforcementUserInput({
  selectedEnforcementType,
  selectedEnforcementPlatform,
  setMissingUserInputFields,
  enforcementUserInput,
  setEnforcementUserInput,
  protectedAssets,
  setMoreInfoInputs,
}) {
  const [requiredUserInputFields, setRequiredUserInputFields] = useState([])

  useEffect(() => {
    if (
      selectedEnforcementPlatform === SocialMediaPlatform.TIKTOK.toLowerCase() &&
      selectedEnforcementType === ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase()
    ) {
      setRequiredUserInputFields([COPYRIGHT_TYPE])
    } else if (
      selectedEnforcementPlatform === SocialMediaPlatform.TWITTER.toLowerCase() &&
      selectedEnforcementType === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase() &&
      protectedAssets?.length > 0
    ) {
      setRequiredUserInputFields([PROTECTED_SLUG])
    } else if (
      selectedEnforcementPlatform === SocialMediaPlatform.YOUTUBE.toLowerCase() &&
      selectedEnforcementType === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase()
    ) {
      setRequiredUserInputFields([YOUTUBE_BRAND_TYPE, YOUTUBE_CONTENT_TYPE])
    } else if (
      isSocialMediaPlatformMeta(selectedEnforcementPlatform) &&
      selectedEnforcementType === ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase()
    ) {
      setRequiredUserInputFields([COPYRIGHT_TYPE, COPYRIGHTED_CONTENT_URL])
    } else if (
      selectedEnforcementPlatform ===
        DomainEnforcementPlatform.CLOUDFLARE.toLowerCase() &&
      selectedEnforcementType === ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase()
    ) {
      setRequiredUserInputFields([COPYRIGHT_ORIGINAL_WORK_DESCRIPTION])
    } else {
      setRequiredUserInputFields([])
    }
  }, [selectedEnforcementType, selectedEnforcementPlatform, protectedAssets])

  useEffect(() => {
    setMissingUserInputFields(
      Object.keys(enforcementUserInput)?.length > 0
        ? requiredUserInputFields.filter(
            (requiredField) =>
              !Object.prototype.hasOwnProperty.call(
                enforcementUserInput,
                requiredField,
              ),
          )
        : requiredUserInputFields,
    )
  }, [enforcementUserInput, requiredUserInputFields])

  if (
    selectedEnforcementPlatform === SocialMediaPlatform.TIKTOK.toLowerCase() &&
    selectedEnforcementType === ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase()
  ) {
    const tiktokCopyrightOptions = [
      'Video',
      'Original Music',
      'Non-Music Audio',
      'Photo / Picture',
      'Logo',
      'Other',
    ]
    return (
      <CopyrightSubtypeDropdown
        dropdownOptions={tiktokCopyrightOptions}
        setEnforcementUserInput={setEnforcementUserInput}
        setMoreInfoInputs={setMoreInfoInputs}
      />
    )
  } else if (
    selectedEnforcementPlatform === SocialMediaPlatform.YOUTUBE.toLowerCase() &&
    selectedEnforcementType === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase()
  ) {
    const youtubeContentOptions = ['Channel', 'Video']
    const youtubeBrandOptions = ['Wordmark & logo', 'Wordmark', 'Logo']
    return (
      <VStack>
        <YoutubeContentDropdown
          contentDropDownOptions={youtubeContentOptions}
          setEnforcementUserInput={setEnforcementUserInput}
        />

        <YoutubeBrandDropdown
          brandDropDownOptions={youtubeBrandOptions}
          setEnforcementUserInput={setEnforcementUserInput}
        />
      </VStack>
    )
  } else if (
    selectedEnforcementPlatform === SocialMediaPlatform.TWITTER.toLowerCase() &&
    selectedEnforcementType === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase()
  ) {
    const twitterProtectedSlugs =
      protectedAssets?.map((asset) => asset.social_media.slug) || []
    return twitterProtectedSlugs?.length > 0 ? (
      <OriginalAccountDropdown
        dropdownOptions={twitterProtectedSlugs}
        setEnforcementUserInput={setEnforcementUserInput}
      />
    ) : (
      <Flex alignItems="center">
        <Icon as={WarningIcon} color={'yellow.500'} fontSize={12} />

        <Text paddingLeft="2">
          No protected slugs! Not required, but adding one will help make a stronger
          enforcement case.
        </Text>
      </Flex>
    )
  } else if (
    isSocialMediaPlatformMeta(selectedEnforcementPlatform) &&
    selectedEnforcementType === ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase()
  ) {
    return (
      <VStack>
        <CopyrightSubtypeDropdown
          dropdownOptions={Object.keys(MetaReportingContentType)}
          setEnforcementUserInput={setEnforcementUserInput}
          setMoreInfoInputs={setMoreInfoInputs}
        />

        <CopyrightedContentUrlDropdown
          setEnforcementUserInput={setEnforcementUserInput}
        />
      </VStack>
    )
  } else if (
    selectedEnforcementPlatform ===
      DomainEnforcementPlatform.CLOUDFLARE.toLowerCase() &&
    selectedEnforcementType === ENFORCEMENT_TYPE.COPYRIGHT.toLowerCase()
  ) {
    return (
      <CopyrightOriginalWorkDescriptionInput
        setEnforcementUserInput={setEnforcementUserInput}
      />
    )
  }

  return <></>
}

function CopyrightOriginalWorkDescriptionInput({ setEnforcementUserInput }) {
  const [copyrightOriginalWorkDescription, setCopyrightOriginalWorkDescription] =
    useState('')

  return (
    <FormControl>
      <VStack align="left">
        <DoppelFormLabel
          formLabel="Original Work Description"
          helperText="Enter a description of the original work that is being copied."
        />

        <Box>
          <Textarea
            maxLength={COPYRIGHT_ORIGINAL_WORK_DESCRIPTION_CHAR_LIMIT}
            onChange={(e) => {
              setCopyrightOriginalWorkDescription(e.target.value)
              setEnforcementUserInput((prevState) => ({
                ...prevState,
                [COPYRIGHT_ORIGINAL_WORK_DESCRIPTION]: e.target.value,
              }))
            }}
            value={copyrightOriginalWorkDescription}
          />

          <Text color="gray.500" fontSize="sm" mt={2}>
            {copyrightOriginalWorkDescription.length}/
            {COPYRIGHT_ORIGINAL_WORK_DESCRIPTION_CHAR_LIMIT} characters
          </Text>
        </Box>
      </VStack>
    </FormControl>
  )
}

function CopyrightSubtypeDropdown({
  setEnforcementUserInput,
  dropdownOptions,
  setMoreInfoInputs,
}) {
  const [selectedCopyrightType, setSelectedCopyrightType] = useState('')

  return (
    <FormControl isRequired>
      <VStack align="left">
        <DoppelFormLabel
          formLabel="Copyrighted Content Type"
          helperText="Select the copyrighted content type"
        />

        <Menu>
          <MenuButton as={Button} minW={'75px'}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="13">{selectedCopyrightType}</Text>

              <ChevronDownIcon />
            </Flex>
          </MenuButton>

          <MenuList maxHeight="280px" overflow="scroll" zIndex={MENU_LIST_ZINDEX}>
            {dropdownOptions.map((option) => (
              <MenuItem
                fontSize="13"
                key={option}
                onClick={() => {
                  setSelectedCopyrightType(option)
                  setEnforcementUserInput((prevState) => ({
                    ...prevState,
                    [COPYRIGHT_TYPE]: option,
                  }))
                  setMoreInfoInputs((prevState) => ({
                    ...prevState,
                    [COPYRIGHT_TYPE]: option.toLowerCase(),
                  }))
                }}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </VStack>
    </FormControl>
  )
}

function CopyrightedContentUrlDropdown({ setEnforcementUserInput }) {
  const [copyrightedContentUrl, setCopyrightedContentUrl] = useState('')

  return (
    <FormControl isRequired>
      <VStack align="left">
        <DoppelFormLabel
          formLabel="Copyrighted Content URL"
          helperText="Select the copyrighted content's URL"
        />

        <Input
          onChange={(e) => {
            setCopyrightedContentUrl(e.target.value)
            setEnforcementUserInput((prevState) => ({
              ...prevState,
              [COPYRIGHTED_CONTENT_URL]: e.target.value,
            }))
          }}
          value={copyrightedContentUrl}
        />
      </VStack>
    </FormControl>
  )
}

function YoutubeContentDropdown({ setEnforcementUserInput, contentDropDownOptions }) {
  const [selectedContent, setSelectedContent] = useState('')

  return (
    <FormControl isRequired>
      <VStack align="left">
        <DoppelFormLabel
          formLabel="Content Type"
          helperText="Select the content type"
        />

        <Menu>
          <MenuButton as={Button} minW={'75px'}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="13">{selectedContent}</Text>

              <ChevronDownIcon />
            </Flex>
          </MenuButton>

          <MenuList maxHeight="280px" overflow="scroll" zIndex={MENU_LIST_ZINDEX}>
            {contentDropDownOptions.map((option) => (
              <MenuItem
                fontSize="13"
                key={option}
                onClick={() => {
                  setSelectedContent(option)
                  setEnforcementUserInput((prevState) => ({
                    ...prevState,
                    [YOUTUBE_CONTENT_TYPE]: option,
                  }))
                }}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </VStack>
    </FormControl>
  )
}

function YoutubeBrandDropdown({ setEnforcementUserInput, brandDropDownOptions }) {
  const [selectedBrand, setSelectedBrand] = useState('')

  return (
    <FormControl isRequired>
      <VStack align="left">
        <DoppelFormLabel formLabel="Brand Type" helperText="Select the brand type" />

        <Menu>
          <MenuButton as={Button} minW={'75px'}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="13">{selectedBrand}</Text>

              <ChevronDownIcon />
            </Flex>
          </MenuButton>

          <MenuList maxHeight="280px" overflow="scroll" zIndex={MENU_LIST_ZINDEX}>
            {brandDropDownOptions.map((option) => (
              <MenuItem
                fontSize="13"
                key={option}
                onClick={() => {
                  setSelectedBrand(option)
                  setEnforcementUserInput((prevState) => ({
                    ...prevState,
                    [YOUTUBE_BRAND_TYPE]: option,
                  }))
                }}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </VStack>
    </FormControl>
  )
}

function OriginalAccountDropdown({ setEnforcementUserInput, dropdownOptions }) {
  const [selectedProtectedSlug, setSelectedProtectedSlug] = useState('')

  return (
    <FormControl isRequired>
      <VStack align="left">
        <DoppelFormLabel
          formLabel="Protected Slug"
          helperText="Select the original protected asset's slug"
        />

        <Menu>
          <MenuButton as={Button} maxWidth="20%" mb="4">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="13" minWidth="100px">
                {selectedProtectedSlug}
              </Text>

              <ChevronDownIcon />
            </Flex>
          </MenuButton>

          <MenuList maxHeight="280px" overflow="scroll" zIndex={MENU_LIST_ZINDEX}>
            {dropdownOptions.map((option) => (
              <MenuItem
                fontSize="13"
                key={option}
                onClick={() => {
                  setSelectedProtectedSlug(option)
                  setEnforcementUserInput((prevState) => ({
                    ...prevState,
                    [PROTECTED_SLUG]: option,
                  }))
                }}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </VStack>
    </FormControl>
  )
}
