import {
  Box,
  Button,
  CloseButton,
  FormControl,
  Image,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FileUploadType } from '../../../utils/constants'
import { useState } from 'react'
import axios from 'axios'
import FileDropzone from '../../shared/file_dropzone'
import {
  useUpdateSpoofingReportScreenshotMutation,
  useUpdateFullUrlScreenshotMutation,
} from '@/generated/graphql'

type Props = {
  onCompleted: () => void
  selectedSpoofReport: any
  screenshotName?: string
  setUpdateScreenshots: any
  setImageUrl?: any
}

const SpoofDetailUploadScreenshotForm: React.FC<Props> = ({
  onCompleted: onCompleted,
  selectedSpoofReport,
  screenshotName = null,
  setUpdateScreenshots,
  setImageUrl,
}) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploadMessage, setUploadMessage] = useState('')
  const [updateSpoofingReportScreenshot] = useUpdateSpoofingReportScreenshotMutation()
  const [updateFullUrlScreenshot] = useUpdateFullUrlScreenshotMutation()

  async function uploadManualScreenshotRequest(file: any) {
    const formData = new FormData()
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        accept: 'application/json',
      },
    }

    const fileName = screenshotName || `${selectedSpoofReport.id}`
    const organizationId = selectedSpoofReport.organization.id
    formData.append('filename', fileName)
    formData.append('type', FileUploadType.REPORT_SCREENSHOT)
    formData.append('file', file)
    formData.append('metadata', JSON.stringify({ org_manual_upload: organizationId }))
    setUploadMessage(`Uploading screenshot ...`)

    try {
      const response = await axios.post(
        'https://us-west1-nft-index.cloudfunctions.net/upload-file-to-gcs',
        formData,
        config,
      )
      const responseEntityUrl = response.data['public_url']
      let resetUrl = false

      // fallback case where versioned screenshot is not supported at all - store directly at report level
      if (!screenshotName) {
        resetUrl = true
        await updateSpoofingReportScreenshot({
          variables: {
            id: selectedSpoofReport.id,
            screenshot_url: responseEntityUrl,
          },
        })
      }

      if (onCompleted) {
        if (resetUrl) {
          setImageUrl(responseEntityUrl)
        }
        setUpdateScreenshots(true)
        onCompleted()
      }
    } catch (error) {
      setUploadMessage(`Upload failed for screenshot`)
    }
  }

  const renderUploadScreenshotButton = () => {
    return (
      <Button
        _hover={{ background: '#101D44' }}
        bgColor="blue.600"
        color="white"
        id="submit-screenshot-button"
        isDisabled={!selectedFile}
        onClick={async () => {
          uploadManualScreenshotRequest(selectedFile)
        }}
        width="200px"
      >
        Submit
      </Button>
    )
  }

  const renderFileOrDropzone = () => {
    if (selectedFile) {
      return (
        <>
          <Image maxHeight="75px" src={URL.createObjectURL(selectedFile)} />

          <Spacer />
        </>
      )
    }
    return (
      <Box maxHeight="75px" maxWidth="210px">
        <FileDropzone
          acceptedFileTypes={{
            'image/*': ['.jpeg', '.png'],
          }}
          setSelectedFile={setSelectedFile}
        />
      </Box>
    )
  }

  return (
    <Box position="relative" top="30%" width="450px">
      <CloseButton onClick={onCompleted} position="absolute" right="75px" />

      <VStack spacing={20} width="100%">
        <Stack>
          <FormControl isRequired>{renderFileOrDropzone()}</FormControl>
        </Stack>

        <Stack>
          {uploadMessage ? (
            <Text>{uploadMessage}</Text>
          ) : (
            renderUploadScreenshotButton()
          )}
        </Stack>
      </VStack>
    </Box>
  )
}

export default SpoofDetailUploadScreenshotForm
