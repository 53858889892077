import { PlatformSelectionMethod } from '@/generated/enums'
import { getIconForEnforcementPlatform } from '@/utils/enforcement_utils'
import {
  DOPPEL_BREACH_RED,
  DOPPEL_CYBER_BLUE,
  DOPPEL_DARK,
  DOPPEL_SUCCESS,
} from '@/utils/style'
import { withApollo } from '@apollo/react-hoc'
import { Stack, HStack, Flex, Text, IconButton, Spacer } from '@chakra-ui/react'
import { FaPen } from 'react-icons/fa'
import { FiFile } from 'react-icons/fi'
import { IoMdClose, IoMdAddCircle } from 'react-icons/io'
import DoppelFormLabel from '../shared/forms/doppel_form_label'
import {
  PLATFORM_FOUND_SELECTION_METHODS,
  UNKNOWN_PLATFORM_PREFIX,
} from '@/utils/suggested_enforcements/suggested_enforcement_constants'

import {
  getDomainsPlatformTypeLabel,
  getEnforcementRequestLabel,
} from '@/utils/suggested_enforcements/display_enforcement_utils'

type Props = {
  pos: number
  enforcementRequest: any
  removeEnforcementOnClick: any
  product: string
  rejectEnforcement: boolean
  isSuggestedEnforcement: boolean
  reviewEnforcementOnClick: any
}

const EnforcementRequestDisplay: React.FC<Props> = ({
  pos,
  enforcementRequest,
  removeEnforcementOnClick,
  product,
  rejectEnforcement = true, // true if user can reject enforcement, false if user can add it back
  isSuggestedEnforcement = false, // true if it is a suggested enforcement, false if it is an enforcement request in internal enforcement form
  reviewEnforcementOnClick = null, // function to be called when user clicks on review button
}) => {
  const _getDomainsPlatformTypeLabel = () => {
    return getDomainsPlatformTypeLabel(enforcementRequest.platform_type)
  }

  const getSelectedPlatformName = () => {
    if (
      enforcementRequest.selected_platform_selection_method ===
      PlatformSelectionMethod.UNKNOWN
    ) {
      // Platform unknown only possible in case of domains
      return UNKNOWN_PLATFORM_PREFIX + _getDomainsPlatformTypeLabel()
    } else {
      return enforcementRequest.selected_platform_name
    }
  }

  const createEnforcementRequestPill = (
    platform: string,
    enforcement_type: string,
    file: string,
  ) => {
    return (
      <Flex
        alignItems="center"
        bgColor={DOPPEL_DARK}
        borderRadius="5px"
        justifyContent="space-between"
        key={`enforcement-request-${pos}`}
        mb="1"
        paddingX="2"
        paddingY="1"
        width="250px"
      >
        {getIconForEnforcementPlatform(platform)}

        <Text fontSize={13} ml="1">
          {platform}-{enforcement_type}
        </Text>

        {file && <FiFile />}

        <Spacer />

        <IconButton
          aria-label={'Close'}
          bgColor={rejectEnforcement ? DOPPEL_BREACH_RED : DOPPEL_SUCCESS}
          icon={rejectEnforcement ? <IoMdClose /> : <IoMdAddCircle />}
          marginLeft={2}
          onClick={removeEnforcementOnClick(pos)}
          size="xs"
        />
      </Flex>
    )
  }

  const renderReviewButton = () => {
    return (
      <IconButton
        aria-label={'Review'}
        bgColor={DOPPEL_CYBER_BLUE}
        icon={<FaPen />}
        marginLeft={2}
        onClick={() => reviewEnforcementOnClick(enforcementRequest, pos)}
        size="sm"
      />
    )
  }

  const getEnforcementRequestHelperText = () => {
    if (
      PLATFORM_FOUND_SELECTION_METHODS.includes(
        enforcementRequest.selected_platform_selection_method,
      )
    ) {
      return `Found platform ${enforcementRequest.selected_platform_name}! Please verify the suggested enforcements for this platform.`
    } else if (
      enforcementRequest.selected_platform_selection_method ===
      PlatformSelectionMethod.UNKNOWN
    ) {
      // Platform unknown only possible in case of domains
      const platformTypeLabel = _getDomainsPlatformTypeLabel()
      return `Unable to find '${platformTypeLabel}' for the alert. Please look up the '${platformTypeLabel}' for the alert and use it to send the enforcement request.`
    } else {
      return `Unable to find platform for '${enforcementRequest.selected_platform_name}'. Please check whether '${enforcementRequest.selected_platform_name}' is a valid enforcement platform and verify the corresponding suggested enforcements.`
    }
  }

  const renderSuggestedEnforcementRequestLabel = () => {
    return (
      <DoppelFormLabel
        formLabel={getEnforcementRequestLabel(enforcementRequest, product)}
        helperText={getEnforcementRequestHelperText()}
      />
    )
  }

  const renderSuggestedEnforcement = () => {
    return (
      <>
        {renderSuggestedEnforcementRequestLabel()}

        <HStack>
          {createEnforcementRequestPill(
            getSelectedPlatformName(),
            enforcementRequest.enforcement_type,
            enforcementRequest.file,
          )}

          {reviewEnforcementOnClick && renderReviewButton()}
        </HStack>
      </>
    )
  }

  const renderRegularEnforcement = () => {
    return (
      <HStack>
        {createEnforcementRequestPill(
          enforcementRequest.platform,
          enforcementRequest.type,
          enforcementRequest.file,
        )}
      </HStack>
    )
  }

  return (
    <Stack>
      {isSuggestedEnforcement
        ? renderSuggestedEnforcement()
        : renderRegularEnforcement()}
    </Stack>
  )
}

export default withApollo<Props>(EnforcementRequestDisplay)
