import { EnforcementPlatformTypeLabels } from '@/generated/enums'
import { DOPPEL_BREACH_RED, DOPPEL_WHITE } from '@/utils/style'
import { DisabledSuggestedEnforcement } from '@/utils/suggested_enforcements/suggested_enforcement_types'
import { withApollo } from '@apollo/react-hoc'
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Td,
  Stack,
  HStack,
  Heading,
  Flex,
  Icon,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'

type Props = {
  shownEnforcementNotGeneratedReasons: DisabledSuggestedEnforcement[]
  hiddenEnforcementNotGeneratedReasons: DisabledSuggestedEnforcement[]
}

const DisabledEnforcementsTable: React.FC<Props> = ({
  shownEnforcementNotGeneratedReasons,
  hiddenEnforcementNotGeneratedReasons,
}) => {
  const [
    showAllEnforcementNotGeneratedReasons,
    setShowAllEnforcementNotGeneratedReasons,
  ] = useState(false)

  const createAllReasonRows = (reasons: DisabledSuggestedEnforcement[]) => {
    return reasons.map((reason, index) => (
      <Tr key={index}>{createDisabledSuggestedEnforcementTableRow(reason)}</Tr>
    ))
  }

  const createDisabledEnforcementLabel = (
    platforms,
    enforcement_types,
    platform_type,
    source,
  ) => {
    return (
      platforms.map((platform) =>
        enforcement_types
          .map((type) =>
            EnforcementPlatformTypeLabels[platform_type]
              ? `${EnforcementPlatformTypeLabels[platform_type]}-${type}-${platform}`
              : `${type}-${platform}`,
          )
          .join(',\n\n'),
      ) + `\n\n Not ${source}`
    )
  }

  const createAdditionalInfoLabel = (params) => {
    return params && Object.keys(params).length > 0
      ? `Additional Info: ${Object.entries(params)
          .map(([key, value]) => `${key} is ${value || 'empty'}`)
          .join(',\n\n')}`
      : ''
  }
  const createDisabledSuggestedEnforcementTableRow = (
    disabledEnforcement: DisabledSuggestedEnforcement,
  ) => {
    const {
      platforms,
      enforcement_types,
      reason,
      source,
      params,
      platform_type,
      shouldDisplayInitially,
      requiredAction,
    } = disabledEnforcement

    const disabledEnforcementLabel = createDisabledEnforcementLabel(
      platforms,
      enforcement_types,
      platform_type,
      source,
    )
    const additionalInfo = createAdditionalInfoLabel(params)

    const reasonWithInfo = `${reason}\n\n${additionalInfo}`

    return (
      <>
        <Td>{disabledEnforcementLabel}</Td>

        <Td>{reasonWithInfo}</Td>

        <Td>{requiredAction}</Td>
      </>
    )
  }

  const createDisabledSuggestedEnforcementTableHeader = () => {
    return (
      <HStack>
        <Heading as="h4" color={DOPPEL_BREACH_RED} fontSize={15} size="sm">
          Reason(s) why suggested enforcements were not generated:
        </Heading>

        {hiddenEnforcementNotGeneratedReasons.length > 0 && (
          <Flex
            alignItems="center"
            borderRadius="md"
            color="white"
            cursor="pointer"
            onClick={() =>
              setShowAllEnforcementNotGeneratedReasons(
                !showAllEnforcementNotGeneratedReasons,
              )
            }
            padding="8px"
          >
            <Text>
              {showAllEnforcementNotGeneratedReasons
                ? 'Hide Reasons'
                : 'Show All Reasons'}
            </Text>

            <Icon
              as={
                showAllEnforcementNotGeneratedReasons
                  ? ChevronDownIcon
                  : ChevronRightIcon
              }
            />
          </Flex>
        )}
      </HStack>
    )
  }

  const createDisabledSuggestedEnforcementTableBody = () => {
    return (
      <Table textColor={DOPPEL_WHITE}>
        <Thead>
          <Tr>
            <Th>Enforcement(s)</Th>

            <Th>Reason</Th>

            <Th>Required Action</Th>
          </Tr>
        </Thead>

        <Tbody fontSize={12}>
          {createAllReasonRows(shownEnforcementNotGeneratedReasons)}

          {showAllEnforcementNotGeneratedReasons &&
            createAllReasonRows(hiddenEnforcementNotGeneratedReasons)}
        </Tbody>
      </Table>
    )
  }

  const createDisabledSuggestedEnforcementFullTable = () => {
    return (
      <Stack>
        {createDisabledSuggestedEnforcementTableHeader()}

        {shownEnforcementNotGeneratedReasons.length > 0 ||
        (showAllEnforcementNotGeneratedReasons &&
          hiddenEnforcementNotGeneratedReasons.length > 0) ? (
          createDisabledSuggestedEnforcementTableBody()
        ) : (
          <></>
        )}
      </Stack>
    )
  }

  return (
    <>
      {shownEnforcementNotGeneratedReasons.length > 0 ||
      hiddenEnforcementNotGeneratedReasons.length > 0 ? (
        createDisabledSuggestedEnforcementFullTable()
      ) : (
        <></>
      )}
    </>
  )
}

export default withApollo<Props>(DisabledEnforcementsTable)
