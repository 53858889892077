import { IconButton } from '@chakra-ui/react'
import { IoMdUndo } from 'react-icons/io'
import { useIsEmployeeView } from '../../../hooks/id_token_claims'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../../utils/style'
import { Tooltip } from '@chakra-ui/react'
import { ReactElement } from 'react'

type RetractionButtonProps = {
  retractionFunc: () => void
}

// Retraction button that opens modal to start retractions
function RetractionButton({ retractionFunc }: RetractionButtonProps): ReactElement {
  const [isEmployeeView] = useIsEmployeeView()

  if (!isEmployeeView) return <></>

  return (
    <Tooltip label="Start Retraction">
      <IconButton
        aria-label="retraction button"
        bgColor={DOPPEL_INTERNAL_PURPLE}
        color={DOPPEL_WHITE}
        icon={<IoMdUndo />}
        onClick={() => {
          retractionFunc()
        }}
        size={'xs'}
      />
    </Tooltip>
  )
}

export default RetractionButton
