import { Flex, Textarea } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { DOPPEL_CLICKABLE_GREY, DOPPEL_DARK } from '../../utils/style'
import { useShouldDisableArrowNavigation } from '../../pages/reports'

export default function DetailNotes({
  notes,
  onSave,
}: {
  notes: string
  onSave: (notes: string) => void
}) {
  const [unsavedNotes, setUnsavedNotes] = useState(notes || '')
  const [_, setShouldDisableArrowNavigation] = useShouldDisableArrowNavigation()

  useEffect(() => {
    setUnsavedNotes(notes || '')
  }, [notes])

  const handleBlur = () => {
    if (unsavedNotes != notes) {
      onSave(unsavedNotes)
      setShouldDisableArrowNavigation(false)
    }
  }

  return (
    <Flex>
      <Textarea
        _placeholder={{ opacity: 1, color: 'gray.500' }}
        backgroundColor={DOPPEL_CLICKABLE_GREY}
        color={DOPPEL_DARK}
        fontSize={13}
        marginBottom="20px"
        onBlur={() => handleBlur()}
        onChange={(e) => setUnsavedNotes(e.target.value)}
        onFocus={() => setShouldDisableArrowNavigation(true)}
        placeholder={'Add notes here'}
        value={unsavedNotes}
        width="400px"
      />
    </Flex>
  )
}
