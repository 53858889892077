import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Spacer } from '@chakra-ui/react'

export default function DetailNavBar({ onBackPressed, onExitTapped }) {
  return (
    <Flex left="12px" position="absolute" top="12px" width="100%" zIndex="2">
      <IconButton
        aria-label="back to database"
        borderRadius="500px"
        icon={<ArrowBackIcon />}
        onClick={onBackPressed}
        size={'sm'}
      />

      <Spacer />

      <IconButton
        aria-label="back to database"
        borderRadius="500px"
        icon={<CloseIcon />}
        onClick={onExitTapped}
        right="24px"
        size={'sm'}
      />
    </Flex>
  )
}
