import { Box, Flex, Progress, Text, Tooltip } from '@chakra-ui/react'

export type ProgressState = {
  label: string
  info?: string
}

type ProgressBarProps = {
  states: ProgressState[]
  currentState: string
  isNegative?: boolean
}

const ProgressBar = ({
  states,
  currentState,
  isNegative = false,
}: ProgressBarProps) => {
  const currStatePos = states.findIndex((state) => state.label === currentState)
  const percentCompleted = Math.round((currStatePos / (states.length - 1)) * 100)

  const renderText = (state: ProgressState) => {
    const text = (
      <Text as={state.info ? 'u' : 'p'} fontSize={10}>
        {state.label}
      </Text>
    )

    if (state.info) {
      return <Tooltip label={state.info}>{text}</Tooltip>
    }

    return text
  }

  return (
    <Box w="150px">
      <Progress
        colorScheme={isNegative ? 'red' : 'green'}
        size="md"
        value={percentCompleted}
      />

      <Flex justify="space-between">{states.map((state) => renderText(state))}</Flex>
    </Box>
  )
}

export default ProgressBar
