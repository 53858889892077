import { HStack, Text, Flex, Tooltip, Spacer } from '@chakra-ui/react'
import { IoMdHelpCircle } from 'react-icons/io'
import { ReactElement } from 'react'
import { DOPPEL_WHITE } from '../../utils/style'

type DetailRowProps = {
  title?: string
  leftDetail?: ReactElement
  rightDetail?: ReactElement
  fontSize?: number
  hasMaxTwoColumns?: boolean
  tooltipText?: string
  button?: ReactElement
}

const DetailRow = (props: DetailRowProps) => {
  const {
    title,
    leftDetail,
    rightDetail,
    fontSize,
    hasMaxTwoColumns,
    tooltipText,
    button,
  } = props

  return (
    <Flex alignItems="center" marginBottom="6px" marginTop="6px" overflowX="auto">
      <Flex flex={hasMaxTwoColumns ? 1.45 : 1}>
        <HStack w="100%">
          <Text color={DOPPEL_WHITE} fontSize={fontSize || 13} fontWeight="medium">
            {title || ''}
          </Text>

          {button}

          <Spacer />

          {tooltipText && (
            <Tooltip fontSize="sm" label={tooltipText}>
              <span>
                <IoMdHelpCircle />
              </span>
            </Tooltip>
          )}
        </HStack>
      </Flex>

      <Flex flex={rightDetail ? 2 : 4} overflowX="auto">
        {leftDetail}
      </Flex>

      {rightDetail && <Flex flex={2}>{rightDetail} </Flex>}
    </Flex>
  )
}

export default DetailRow
