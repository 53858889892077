import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Tag,
} from '@chakra-ui/react'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { DOPPEL_SECURE, DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../utils/style'

const EditDropdown = ({
  currentValue,
  values,
  valueToDisplayFunction = (value) => {
    return value
  },
  getColorForValues = (_value) => DOPPEL_SECURE,
  onChange,
  isForEmployee = false,
}) => {
  const [isEmployeeView] = useIsEmployeeView()

  if (isForEmployee && !isEmployeeView) return <></>

  return (
    <Box>
      <Menu>
        <MenuButton
          aria-label={''}
          as={IconButton}
          bgColor={isEmployeeView && isForEmployee && DOPPEL_INTERNAL_PURPLE}
          color={isEmployeeView && isForEmployee && DOPPEL_WHITE}
          icon={<EditIcon />}
          size="xs"
        />

        <MenuList>
          <MenuOptionGroup onChange={onChange} value={currentValue}>
            {values.map((value) => (
              <MenuItemOption key={value} value={value}>
                <Tag
                  bgColor={getColorForValues(value)}
                  color={'#FFFFFF'}
                  fontSize={12}
                  variant="solid"
                  width="fit-content"
                >
                  {valueToDisplayFunction(value)}
                </Tag>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  )
}

export default EditDropdown
